import React, { useContext } from "react"
import { PageProps, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { I18n, i18n, I18nContext, I18nLink } from "~/components/I18n"

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`

export default ({
  data: {
    site: {
      siteMetadata: { title, author, siteUrl },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      author: string
      siteUrl: string
    }
  }
}>) => {
  const { locale, languages } = useContext(I18nContext)

  const pageTitle = i18n(locale!, languages!, {
    pt: "Página não encontrada",
    en: "Page not found",
  })

  return (<>
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: locale,
        dir: `ltr`,
        prefix: "og: http://ogp.me/ns#",
      }}
    >
      <title>404 | {title}</title>
      <link rel="author" href="/humans.txt" />

      <meta name="description" content={pageTitle} />

      <meta property="og:title" content={`404 | ${title}`} />
      <meta property="og:locale" content={locale} />
      <meta property="og:description" content={pageTitle} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={`404 | ${title}`} />
      <meta name="twitter:description" content={pageTitle} />


    </Helmet>

    <div className="is-height-100 is-grid-center is-align-items-center is-text-align-center">
      <h1 className="is-size-0 is-align-self-bottom">{pageTitle}</h1>
      <p className="is-size-5 is-align-self-top">
        <I18nLink to="/">
          <I18n pt="Voltar à página principal" en="Back to homepage" />
        </I18nLink>
      </p>
    </div>
  </>
  )
}
